import classNames from 'classnames';
import React from 'react';
import IconCollection from '../IconCollection/IconCollection';
import css from "./MobileStore.module.css"

const MobileStore = ({className}) => {
    return (
        <div className={classNames(css.mobileStore, className)}>
            <IconCollection name="google_store" />
            <IconCollection name="apple_store" />
        </div>
    );
};

export default MobileStore;